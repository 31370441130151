<template>
  <v-app>
    <v-main>
      <v-app-bar dense dark>
        <v-img
          class="mr-2"
          src="../public/favicon-32x32.png"
          max-width="32px"
          height="32px"
          href="#"
        ></v-img>
        <v-toolbar-title>Elden Ring Character Planner</v-toolbar-title>
      </v-app-bar>
      <v-container id="container">
        <v-card class="stats mb-4" tile>
          <div v-if="$store.state.currentBuild.baseClass">
            <v-row>
              <v-col :cols="12" :md="6" :lg="4">
                <v-card-title class="pt-0 pl-0">Character</v-card-title>
                <v-select
                  outlined
                  dense
                  :items="$store.state.classes"
                  item-text="name"
                  return-object
                  v-model="$store.state.currentBuild.baseClass"
                  @change="updateClass"
                  label="Class"
                  :messages="
                    $store.state.currentBuild.baseClass &&
                    suggestedClass &&
                    suggestedClass !== $store.state.currentBuild.baseClass.name
                      ? 'Optimal starting class: ' + suggestedClass
                      : null
                  "
                >
                </v-select>
                <v-divider class="mb-4"></v-divider>
                <v-text-field
                  outlined
                  dense
                  label="Character Name"
                  v-model="characterName"
                ></v-text-field>
                <v-select
                  outlined
                  dense
                  :items="$store.state.genders"
                  :menu-props="{ top: true, offsetY: true }"
                  label="Gender"
                ></v-select>
                <div
                  v-for="(stat, name) in $store.state.currentBuild.stats"
                  :key="name"
                >
                  <v-text-field
                    outlined
                    dense
                    :label="name.charAt(0).toUpperCase() + name.slice(1)"
                    :disabled="name === 'level'"
                    type="number"
                    min=1
                    max=99
                    oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                    :value="$store.state.currentBuild.stats[name]"
                    @change="
                      updateStat(
                        name,
                        $store.state.currentBuild.stats[name],
                        $event
                      )
                    "
                  >
                    <template slot="append" v-if="name !== 'level'">
                      <v-icon
                        @click="decrementStat(name)"
                        v-if="
                          $store.state.currentBuild.stats[name] >
                          $store.state.currentBuild.baseClass.stats[name]
                        "
                        >remove</v-icon
                      >
                      <v-icon
                        @click="incrementStat(name)"
                        v-if="$store.state.currentBuild.stats[name] < 99"
                        >add</v-icon
                      >
                    </template>
                  </v-text-field>
                </div>
                <v-divider></v-divider>
                <v-row class="pt-8 pb-2">
                  <v-col :cols="12" :md="6" class="pt-0 pb-0">
                    <v-text-field
                      label="Runes Needed For Next Level"
                      :value="requiredRunes.nextRunes"
                      outlined
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="12" :md="6" class="pt-0 pb-0">
                    <v-text-field
                      label="Total Runes Needed"
                      :value="requiredRunes.totalRunes"
                      outlined
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pt-6">
                  <v-col :cols="12" :md="12" class="pb-0">
                    <v-autocomplete
                      label="Spirit Ashes"
                      outlined
                      dense
                      disabled
                    ></v-autocomplete>
                  </v-col>
                  <v-col :cols="12" :md="12" class="pt-0 pb-0">
                    <v-text-field
                      label="Memory Slots"
                      type="number"
                      min="0"
                      max="12"
                      oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                      :value="$store.state.currentBuild.slots"
                      outlined
                      dense
                    >
                      <template slot="append" v-if="name !== 'level'">
                        <v-icon
                          v-if="$store.state.currentBuild.slots > 0"
                          @click="decrementSlot()"
                        >remove</v-icon>
                        <v-icon
                          v-if="$store.state.currentBuild.slots < 12"
                          @click="incrementSlot()"
                        >add</v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col :cols="12" class="pt-0">
                    <v-autocomplete
                      v-for="slots in $store.state.currentBuild.slots"
                      :key="slots"
                      :label="'Memory Slot ' + slots"
                      outlined
                      dense
                      :items="$store.state.sorceries"
                      item-text="name"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pt-8">
                  <v-col :cols="12" :md="6" class="pt-0 pb-0">
                    <v-select label="Arrows" outlined dense disabled></v-select>
                  </v-col>
                  <v-col :cols="12" :md="6" class="pt-0 pb-0">
                    <v-select label="Bolts" outlined dense disabled></v-select>
                  </v-col>
                </v-row>
              </v-col>

              <v-col :cols="12" :md="6" :lg="5">
                <template>
                  <v-card-title class="pl-0 pt-0">
                    {{ $store.state.currentBuild.baseClass.name }}
                  </v-card-title>

                  <p>
                    {{ $store.state.currentBuild.baseClass.message }}
                  </p>

                  <v-row class="pt-2 pb-0">
                    <v-col :cols="12">
                      <v-select
                        outlined
                        dense
                        :items="$store.state.keepsakes"
                        item-text="name"
                        return-object
                        v-model="selectedKeepsake"
                        :menu-props="{ top: true, offsetY: true }"
                        label="Keepsake"
                      ></v-select>
                      <v-select
                        outlined
                        dense
                        :items="$store.state.greatRunes"
                        item-text="name"
                        return-object
                        v-model="selectedGreatRune"
                        :menu-props="{ top: true, offsetY: true }"
                        label="Great Rune"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-card-title class="pl-0 pt-0">
                    Flask of Wondrous Physick
                  </v-card-title>
                  <v-row class="pt-2">
                    <v-col :cols="6">
                      <v-select
                        outlined
                        dense
                        :items="$store.state.physicks"
                        item-text="name"
                        return-object
                        v-model="selectedTear1"
                        :menu-props="{ top: true, offsetY: true }"
                        label="Crystal Tear 1"
                      ></v-select>
                    </v-col>
                    <v-col :cols="6">
                      <v-select
                        outlined
                        dense
                        :items="$store.state.physicks"
                        item-text="name"
                        return-object
                        v-model="selectedTear2"
                        :menu-props="{ top: true, offsetY: true }"
                        label="Crystal Tear 2"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-divider></v-divider>

                  <v-row class="pt-6">
                    <v-col :cols="12">
                      <v-card-title class="py-0 pl-0">Weapons</v-card-title>
                    </v-col>
                    <v-col :cols="6">
                      <WeaponSlot
                        slotType="left1"
                        label="Left Hand"
                      ></WeaponSlot>
                      <WeaponSlot
                        slotType="left2"
                        label="Left Hand"
                      ></WeaponSlot>
                    </v-col>
                    <v-col :cols="6">
                      <WeaponSlot
                        slotType="right1"
                        label="Right Hand"
                      ></WeaponSlot>

                      <WeaponSlot
                        slotType="right2"
                        label="Right Hand"
                      ></WeaponSlot>
                    </v-col>
                  </v-row>

                  <v-divider></v-divider>

                  <v-row class="pt-6">
                    <v-col :cols="12">
                      <v-card-title class="py-0 pl-0">Armor</v-card-title>
                    </v-col>
                    <v-col :cols="6">
                      <ArmorSlot
                        armorType="head"
                        label="Helms"
                        :armors="$store.state.helms"
                      ></ArmorSlot>
                      <ArmorSlot
                        armorType="chest"
                        label="Chest Armor"
                        :armors="$store.state.chests"
                      ></ArmorSlot>
                    </v-col>
                    <v-col :cols="6">
                      <ArmorSlot
                        armorType="gauntlet"
                        label="Gauntlets"
                        :armors="$store.state.gauntlets"
                      ></ArmorSlot>
                      <ArmorSlot
                        armorType="leg"
                        label="Leg Armor"
                        :armors="$store.state.legs"
                      ></ArmorSlot>
                    </v-col>
                  </v-row>

                  <v-divider></v-divider>

                  <v-row class="pt-6">
                    <v-col :cols="12">
                      <v-card-title class="py-0 pl-0">Talismans</v-card-title>
                    </v-col>
                    <v-col :cols="6">
                      <v-autocomplete
                        label="Talisman 1"
                        outlined
                        :items="$store.state.talismans"
                        item-text="name"
                        dense
                      ></v-autocomplete>
                      <v-autocomplete
                        label="Talisman 2"
                        outlined
                        :items="$store.state.talismans"
                        item-text="name"
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col :cols="6">
                      <v-autocomplete
                        label="Talisman 3"
                        outlined
                        :items="$store.state.talismans"
                        item-text="name"
                        dense
                      ></v-autocomplete>
                      <v-autocomplete
                        label="Talisman 4"
                        outlined
                        :items="$store.state.talismans"
                        item-text="name"
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-divider class="d-flex d-md-none"></v-divider>
                </template>
              </v-col>

              <v-col :cols="12" :md="12" :lg="3">
                <v-card-title class="pt-0 pl-0">Adjusted Stats</v-card-title>
                <v-text-field
                  v-for="(stat, name) in $store.state.currentBuild
                    .adjustedStats"
                  :key="name"
                  :label="stat.label"
                  :value="stat.value"
                  outlined
                  dense
                  disabled
                ></v-text-field>

                <v-card-title class="pt-0 pl-0">Resistances</v-card-title>
                <v-text-field
                  v-for="(resistance, name) in $store.state.currentBuild
                    .adjustedResistances"
                  :key="name"
                  :label="resistance.label"
                  :value="resistance.value"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <div>
          <v-row id="suggestedContainer">
            <v-col :cols="12" :md="12">
              <!-- <v-btn outlined tile block @click="toggleLocations">
                {{ !$store.state.showLocations ? "Show" : "Hide" }} Item
                Locations
              </v-btn>
              <v-btn
                outlined
                tile
                block
                class="mt-4"
                :href="'data:' + downloadJson()"
                download="data.json"
              >
                Export Class
              </v-btn>
              <v-btn
                outlined
                tile
                block
                class="mt-4"
                @click="showImport = !showImport"
              >
                Import Class
              </v-btn> -->
              <v-dialog width="1000">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    tile
                    block
                    class="mt-4"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Optimal Class Calculator
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="pb-6">
                    Optimal Class Calculator
                  </v-card-title>

                  <div
                    class="pl-5 pr-5"
                    v-for="(stat, name) in $store.state.currentOptimalBuild.stats"
                    :key="name"
                  >
                    <v-text-field
                      v-if="name !== 'level'"
                      outlined
                      dense
                      :label="name.charAt(0).toUpperCase() + name.slice(1)"
                      :value="$store.state.currentOptimalBuild.stats[name]"
                      tabindex="0"
                      @change="
                        updateOptimalStat(
                          name,
                          $store.state.currentOptimalBuild.stats[name],
                          $event
                        )
                      "
                    >
                      <template slot="append" v-if="name !== 'level'">
                        <v-icon
                          tabindex="1"
                          @click="decrementOptimalStat(name)"
                          v-if="
                            $store.state.currentOptimalBuild.stats[name] > 0
                          "
                          >remove</v-icon
                        >
                        <v-icon
                          tabindex="1"
                          @click="incrementOptimalStat(name)"
                          v-if="
                            $store.state.currentOptimalBuild.stats[name] <= 99
                          "
                          >add</v-icon
                        >
                      </template>
                    </v-text-field>
                  </div>

                  <v-divider></v-divider>

                  <v-list dense class="pl-5 pr-5">
                    <v-subheader>Most Optimal Classes in Order</v-subheader>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Rank</th>
                            <th class="text-left">Class Name</th>
                            <th
                              class="text-left"
                              v-for="(item, i) in $store.state.classes[0].stats"
                              :key="i"
                            >
                              {{ i.charAt(0).toUpperCase() + i.slice(1) }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, i) in optimalSuggestedClass"
                            :key="item.name"
                          >
                            <td>{{ i + 1 }}</td>
                            <td>{{ item.name }}</td>
                            <td v-for="(stat, j) in item.stats" :key="j">
                              {{ stat }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-list>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="primary" text @click="applyStats(displayStats)">Apply Class And Stats</v-btn> -->
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-textarea
                outlined
                tile
                v-show="showImport"
                name="input-7-4"
                label="Outlined textarea"
              ></v-textarea>
            </v-col>
          </v-row>
        </div>
        <EquipmentLocations
          :expand="$store.state.showLocations"
        ></EquipmentLocations>
      </v-container>
    </v-main>
    <v-container id="bottom-container">
      <div id="bottom-text">
        Created By&nbsp;<a href="https://www.twitch.tv/celtachaos">Celta</a>
        &nbsp;and&nbsp;<a href="https://www.twitch.tv/ririshii">Ririshii</a>.<br/>
        Please consider following/subscribing to our channels to support the website.<br/>
        This site is a work in progress. You'll need to cross reference with a wiki/the game on data for items until we've put them in.
      </div>
    </v-container>
    <template>
      <footer>
        <cookie-law theme="dark-lime">
          <div slot="message">
            This site uses 🍪. We use them to track page visits and other basic analytics.
          </div>
        </cookie-law>
      </footer>
    </template>
  </v-app>
</template>

<script>
import EquipmentLocations from "./components/EquipmentLocations.vue";
import ArmorSlot from "./components/ArmorSlot.vue";
import WeaponSlot from "./components/WeaponSlot.vue";
import CookieLaw from 'vue-cookie-law'

export default {
  name: "App",
  components: {
    CookieLaw,
    EquipmentLocations,
    ArmorSlot,
    WeaponSlot,
  },
  methods: {
    updateClass(newClass) {
      this.$store.commit("updateClass", newClass);
    },
    updateStat(statName, oldVal, newVal) {
      this.$store.commit("updateStat", {
        statName: statName,
        oldVal: oldVal,
        newVal: newVal,
      });
    },
    updateOptimalStat(statName, oldVal, newVal) {
      this.$store.commit("updateOptimalStat", {
        statName: statName,
        oldVal: oldVal,
        newVal: newVal,
      });
    },
    incrementStat(statName) {
      this.$store.commit("incrementStat", statName);
    },
    decrementStat(statName) {
      this.$store.commit("decrementStat", statName);
    },
    incrementOptimalStat(statName) {
      this.$store.commit("incrementOptimalStat", statName);
    },
    decrementOptimalStat(statName) {
      this.$store.commit("decrementOptimalStat", statName);
    },
    incrementSlot() {
      this.$store.commit("incrementSlot");
    },
    decrementSlot() {
      this.$store.commit("decrementSlot");
    },
    toggleLocations() {
      this.$store.commit("toggleLocations");
    },
    downloadJson() {
      var obj = {
        characterName: this.characterName,
        currentBuild: Object(this.$store.state.currentBuild),
        selectedGreatRune: this.selectedGreatRune,
        selectedKeepsake: this.selectedKeepsake,
      };

      return (
        "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj))
      );
    },
  },
  computed: {
    requiredRunes() {
      let theCalculator = this;
      let currentBuild = theCalculator.$store.state.currentBuild;
      let destinationLevel = currentBuild.stats.level + 1;
      let currentClass = currentBuild.baseClass;
      let baseSouls = 673;
      let totalRunes = 0;
      let nextRunes = 0;

      if (destinationLevel === 2) {
        nextRunes = baseSouls;
      } else if (destinationLevel < 12) {
        nextRunes = Math.floor(baseSouls * ((0.025 * (destinationLevel - 2)) + 1));
      }

      if (destinationLevel < 13) {
        for (let i = 1; i < destinationLevel - currentClass.stats.level; i++) {
          if (i + currentClass.stats.level < 12) {
            totalRunes += Math.floor(baseSouls * ((0.025 * (destinationLevel - (2 + i))) + 1))
          } 
        } 
      } else {
        for (let i = 1; i < (12 - currentClass.stats.level); i++) {
          totalRunes += Math.floor(baseSouls * ((0.025 * (12 - (2 + i))) + 1))
        }

        for (let i = 12; i < destinationLevel; i++) {
          totalRunes += Math.floor(0.02 * (i**3) + 3.06 * (i**2) + (105.6 * i) - 895)
        }
      }

      if (nextRunes > 0) {
        return { totalRunes: totalRunes, nextRunes: nextRunes }
      }
      return { totalRunes: totalRunes, nextRunes: Math.floor(0.02 * (destinationLevel**3) + 3.06 * (destinationLevel**2) + (105.6 * destinationLevel) - 895) }
    },
    suggestedClass() {
      let theCalculator = this;
      let classes = theCalculator.$store.state.classes;
      let currentBuild = theCalculator.$store.state.currentBuild;
      let currentClass = currentBuild.baseClass;
      let currentStats = currentBuild.stats;
      let bestLevel = currentStats.level;

      if (currentClass) {
        let suggestedClass = currentClass.name;
        let desiredStats = {};

        Object.keys(currentClass.stats).forEach((stat) => {
          if (stat !== "level") {
            if (currentStats[stat] - currentClass.stats[stat] !== 0) {
              desiredStats[stat] = currentStats[stat];
            }
          }
        });

        if (Object.keys(desiredStats).length > 0) {
          classes.forEach((baseClass) => {
            let classStats = baseClass.stats;
            let classInvestment = 0;

            Object.keys(classStats).forEach((stat) => {
              if (stat !== "level") {
                // if stat changed
                if (desiredStats[stat]) {
                  // calc the difference for the class to hit that level
                  let difference =
                    desiredStats[stat] > classStats[stat]
                      ? desiredStats[stat] - classStats[stat]
                      : 0;
                  classInvestment += difference;
                }
              }
            });

            let totalLevel = classInvestment + classStats.level;
            if (totalLevel < bestLevel) {
              bestLevel = totalLevel;
              suggestedClass = baseClass.name;
            }
          });

          return suggestedClass;
        }
      }
      return "";
    },
    optimalSuggestedClass() {
      let theCalculator = this;
      let classes = theCalculator.$store.state.classes;
      let currentBuild = theCalculator.$store.state.currentOptimalBuild;
      let originalClasses = [];
      let desiredStats = [];
      let displayStats = []

      // copy the original classes
      Object.values(classes).forEach((startingClass) => {
        originalClasses.push(JSON.parse(JSON.stringify(startingClass)))
      });

      // check if desired stats are actually desired
      Object.entries(currentBuild.stats).forEach((stat) => {
        if (stat[0] !== "level") {
          if (currentBuild.stats[stat[0]] !== 0 && currentBuild.stats[stat[0]] >= 6) {
            desiredStats.push({statName: stat[0], value: currentBuild.stats[stat[0]]});
          }
        }
      });

      if (desiredStats.length > 0) {

        // compare the desired stats with the original classes
        Object.values(originalClasses).forEach((originalClass) => {
          let difference = [];
          Object.entries(originalClass.stats).forEach(originalStat => {

            Object.values(desiredStats).forEach(desiredStat => {
              if (originalStat[0] !== "level" && desiredStat.statName === originalStat[0]) {
                // if stat changed, push difference
                difference.push({statName: originalStat[0], statDiff: desiredStat.value > originalStat[1] ? desiredStat.value - originalStat[1] : 0});

                // if stat is higher, update the currentBuild
                if (desiredStat.value > originalStat[1]) {
                  originalClass.stats[desiredStat.statName] = desiredStat.value
                }
              }
            })
          })

          // sum the differences
          let totalDiff = 0;
          if (difference.length > 0) {
            totalDiff = difference.reduce((a,b) => {
              return {statName: difference.statName, statDiff: a.statDiff + b.statDiff}
            })
          }
          //  Add level to class for every positive difference
          originalClass.stats.level = originalClass.stats.level + totalDiff.statDiff;
          displayStats.push({statDiff: totalDiff, originalClass: originalClass})
        });
        // keep track of level increase based off of the desired stats
        return displayStats.sort((a, b) => {
          let diff = a.originalClass.stats.level - b.originalClass.stats.level;
          if (diff === 0) {
            return Object.values(b.originalClass.stats).reduce((a,b) => a + b) - Object.values(a.originalClass.stats).reduce((a,b) => a + b);
          } else {
            return diff;
          }
        }).map(displayedStat => displayedStat.originalClass)
      }
      return theCalculator.$store.state.classes;
    },
  },
  data: () => ({
    selectedKeepsake: null,
    selectedGreatRune: null,
    showImport: false,
    characterName: "",
    displayInfusions: false,
  }),
  beforeMount() {
    this.$store.commit("updateClass", this.$store.state.classes[0]);
  },
};
</script>
<style>
@import url("https://rsms.me/inter/inter.css");
.v-application {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  .v-application {
    font-family: "Inter var", sans-serif;
  }
}
.v-toolbar__title {
  font-weight: 600;
}
.stats {
  padding: 20px;
}
#container {
  padding: 50px 20px;
}
#header,
#suggestedContainer,
#bottom-text {
  text-align: center;
}
#bottom-container {
  position: relative;
  bottom: 0;
}
#locations {
  padding: 4px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0;
}
.v-messages__message {
  color: #4CAF50;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>
