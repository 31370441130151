<template>
  <v-autocomplete 
    :label="label"
    :items="$store.state.weapons"
    item-text="name"
    item-value="id"
    return-object
    cache-items
    outlined
    dense
    v-model="selectedWeapon"
    @change="updateWeapon(selectedWeapon)"
  >
    <template slot="append">
      <v-menu
        top
        @click:append="displayInfusions = !displayInfusions"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            plain
            height="24"
            width="24"
            v-ripple="false"
            v-bind="attrs"
            v-on="on"
          >
            <v-img
              height="24"
              width="24"
              :src="selectedInfusion.image">
            </v-img>
          </v-btn>
          
        </template>

        <v-list>
          <v-list-item
            link
            v-for="(infusion, index) in infusions"
            :key="index"
            @click="updateInfusion(infusion, index)"
          >
            <v-list-item-title>
              <v-list-item-avatar tile>
                <v-img :src="infusion.image"></v-img>
              </v-list-item-avatar>
              {{ infusion.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- <v-menu
        top
        @click:append="displaySkills = !displaySkills"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            icon
            plain
            height="24"
            width="24"
            v-ripple="false"
            v-bind="attrs"
            v-on="on"
            disabled
          >
            <v-img
              height="24"
              width="24"
              src="https://fakeimg.pl/24x24/121212/1e1e1e/">
            </v-img>
          </v-btn>
          
        </template>

        <v-list>
          <v-list-item
            link
            v-for="(skill, index) in skills"
            :key="index"
            @click="updateSkills(skill, index)"
          >
            <v-list-item-title>
              <v-list-item-avatar>
                <v-img :src="skill.image"></v-img>
              </v-list-item-avatar>
              {{ skill.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </template>
  </v-autocomplete>
</template>

<script>
  export default {
    name: 'WeaponSlot',
    props: {
      slotType: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      }
    },
    methods: {
      updateWeapon: function(newWeapon){
        this.$store.commit('updateWeapon', {type: this.slotType, selected: newWeapon});
      },
      updateInfusion: function(newInfusion){
        this.selectedInfusion = newInfusion;
        this.$store.commit('updateWeaponInfusion', {type: this.slotType, selected: newInfusion});
      },
      updateSkills: function(newSkill){
        this.$store.commit('updateWeaponSkill', {type: this.slotType, selected: newSkill});
      }
    },
    data: () => ({
      displayInfusions: false,
      displaySkills: false,
      selectedInfusion: {
        image: require("../../imgs/standard.png"),
        title: "Standard",
      },
      // TODO: Get list of skills from json
      infusions: [
        {
          image: require("../../imgs/standard.png"),
          title: "Standard",
        },
        {
          image: require("../../imgs/heavy.png"),
          title: "Heavy",
        },
        {
          image: require("../../imgs/keen.png"),
          title: "Keen",
        },
        {
          image: require("../../imgs/quality.png"),
          title: "Quality",
        },
        {
          image: require("../../imgs/magic.png"),
          title: "Magic",
        },
        {
          image: require("../../imgs/sacred.png"),
          title: "Sacred",
        },
        {
          image: require("../../imgs/occult.png"),
          title: "Occult",
        },
        {
          image: require("../../imgs/lightning.png"),
          title: "Lightning",
        },
        {
          image: require("../../imgs/fire.png"),
          title: "Fire",
        },
        {
          image: require("../../imgs/cold.png"),
          title: "Cold",
        },
        {
          image: require("../../imgs/poison.png"),
          title: "Poison",
        },

        {
          image: require("../../imgs/blood.png"),
          title: "Blood",
        },
      ],
      skills: [
        {
          image: "https://fakeimg.pl/40x40/121212/1e1e1e/",
          title: "Click Me 2",
        },
        {
          image: "https://fakeimg.pl/40x40/121212/1e1e1e/",
          title: "Click Me 2",
        },
      ],
    }),
  }
</script>
