import Vue from 'vue'
import Vuex from 'vuex'
import talismansJson from '../../json/talismans.json'
import greatRunesJson from '../../json/great_runes.json'
import physickJson from '../../json/physicks.json'
import weaponsJson from '../../json/weapons.json'
import sorceriesJson from '../../json/sorceries.json'

Vue.use(Vuex)


const classes = [
  {
    name: 'Hero',
    message: "A stalwart hero, at home with a battleaxe, descended from a badlands cheiftain.",
    stats: {
      level: 7,
      vigor: 14,
      mind: 9,
      endurance: 12,
      strength: 16,
      dexterity: 9,
      intelligence: 7,
      faith: 8,
      arcane: 11
    }
  },
  {
    name: 'Bandit',
    message: "A bandit who lives and dies by their arrows. A ranged combat specialist.",
    stats: {
      level: 5,
      vigor: 10,
      mind: 11,
      endurance: 10,
      strength: 9,
      dexterity: 13,
      intelligence: 9,
      faith: 8,
      arcane: 14
    }
  },
  {
    name: 'Astrologer',
    message: "A scholar who reads fate in the stars. Heir to the school of glintstone sorcery.",
    stats: {
      level: 6,
      vigor: 9,
      mind: 15,
      endurance: 9,
      strength: 8,
      dexterity: 12,
      intelligence: 16,
      faith: 7,
      arcane: 9
    }
  },
  {
    name: 'Warrior',
    message: "A twinblade-wielding warrior from a nomadic tribe. An origin of exceptional technique.",
    stats: {
      level: 8,
      vigor: 11,
      mind: 12,
      endurance: 11,
      strength: 10,
      dexterity: 16,
      intelligence: 10,
      faith: 8,
      arcane: 9
    }
  },
  {
    name: 'Prisoner',
    message: "A prisoner bound in an iron mask. Having lived amoung the elite prior to sentencing, they're studied in glintstone sorceries.",
    stats: {
      level: 9,
      vigor: 11,
      mind: 12,
      endurance: 11,
      strength: 11,
      dexterity: 14,
      intelligence: 14,
      faith: 6,
      arcane: 9
    }
  },
  {
    name: 'Confessor',
    message: "A church confessor adept at covert operations. Reliant on sword technique and incantations.",
    stats: {
      level: 10,
      vigor: 10,
      mind: 13,
      endurance: 10,
      strength: 12,
      dexterity: 12,
      intelligence: 9,
      faith: 14,
      arcane: 9
    }
  },
  {
    name: 'Wretch',
    message: "A poor sod, naked as the day they were born. A club is all they have.",
    stats: {
      level: 1,
      vigor: 10,
      mind: 10,
      endurance: 10,
      strength: 10,
      dexterity: 10,
      intelligence: 10,
      faith: 10,
      arcane: 10
    }
  },
  {
    name: 'Vagabond',
    message: "A knight exiled from their homeland to wander. A solid, armor clad origin.",
    stats: {
      level: 9,
      vigor: 15,
      mind: 10,
      endurance: 11,
      strength: 14,
      dexterity: 13,
      intelligence: 9,
      faith: 9,
      arcane: 7
    }
  },
  {
    name: 'Prophet',
    message: "A seer ostracized for inauspicious prophecies. Well versed in healing incantations.",
    stats: {
      level: 7,
      vigor: 10,
      mind: 14,
      endurance: 8,
      strength: 11,
      dexterity: 10,
      intelligence: 7,
      faith: 16,
      arcane: 11
    }
  },
  {
    name: 'Samurai',
    message: "A capable fighter from the distant Land of Reeds. Handy with katana and longbows.",
    stats: {
      level: 9,
      vigor: 12,
      mind: 11,
      endurance: 13,
      strength: 12,
      dexterity: 15,
      intelligence: 9,
      faith: 8,
      arcane: 8
    }
  }
];

const greatRunes = greatRunesJson.great_runes

const keepsakes = [
  "Boiled Prawn",
  "Bewitching Branch",
  "Cracked Pot",
  "Crimson Amber Medallion",
  "Fanged Imp Ashes",
  "Golden Seed",
  "Land's Between Rune",
  "Shabriri's Woe",
  "Stonesword Key",
];

const genders = ["Male", "Female"];

const talismans = talismansJson.talismans;

const physicks = physickJson.physicks;

const weapons = weaponsJson.weapons;

const sorceries = sorceriesJson.sorceries;

const initialStats = {
  baseClass: null,
  requiredRunes: 0,
  totalRunes: 0,
  stats: {
    level: 0,
    vigor: 0,
    mind: 0,
    endurance: 0,
    strength: 0,
    dexterity: 0,
    intelligence: 0,
    faith: 0,
    arcane: 0,
  },
  slots: 0,
  adjustedStats: {
    hp: {
      label: 'HP',
      value: 0
    },
    fp: {
      label: 'FP',
      value: 0
    },
    stamina: {
      label: 'Stamina',
      value: 0
    },
    equipLoad: {
      label: 'Equip Load',
      value: 0
    },
    weightLoad: {
      label: 'Weight Load',
      value: 0
    },
    poise: {
      label: 'Poise',
      value: 0
    },
    itemDiscovery: {
      label: 'Item Discovery',
      value: 0
    },
    attunementSlots: {
      label: 'Attunement Slots',
      value: 0
    },
    staminaRegen: {
      label: 'Stamina Regeneration',
      value: 0
    }
  },
  adjustedResistances: {
    physical: {
      label: 'Physical',
      value: 0
    },
    strike: {
      label: 'Strike',
      value: 0
    },
    slash: {
      label: 'Slash',
      value: 0
    },
    pierce: {
      label: 'Pierce',
      value: 0
    },
    magic: {
      label: 'Magic',
      value: 0
    },
    fire: {
      label: 'Fire',
      value: 0
    },
    lightning: {
      label: 'Lightning',
      value: 0
    },
    holy: {
      label: 'Holy',
      value: 0
    },
    immunity: {
      label: 'Immunity',
      value: 0
    },
    robustness: {
      label: 'Robustness',
      value: 0
    },
    focus: {
      label: 'Focus',
      value: 0
    },
    death: {
      label: 'Death',
      value: 0
    },
  },
};
const optimalInitialStats = Object.assign({}, initialStats);

export default new Vuex.Store({
  state: {
    currentBuild: initialStats,
    currentOptimalBuild: optimalInitialStats,
    classes: classes,
    greatRunes: greatRunes,
    keepsakes: keepsakes,
    talismans: talismans,
    physicks: physicks,
    weapons: weapons,
    sorceries: sorceries,
    genders: genders,
    // TODO: Move these elsewhere; just here as an example/proof of concept
    helms: [
      {
        id: 0,
        name: 'A Helmet',
        value: {
          some: 1,
          kind: 2,
          of: 420,
          stats: 69
        }
      },
      {
        id: 1,
        name: 'Another Helmet',
        value: {
          some: 1,
          kind: 2,
          of: 420,
          stats: 69
        }
      },
    ],
    chests: [],
    gauntlets: [],
    legs: [],
    showLocations: false,
  },
  mutations: {
    updateClass(state, newClass) {
      state.currentBuild.baseClass = Object.assign({}, newClass);
      state.currentBuild.stats = Object.assign({}, newClass.stats);
    },

    // update stat for manual entry
    // TODO - Update required runes
    updateStat(state, updateDetails) {
      if (typeof updateDetails.newVal === "string") {
        let cleanedNew = Number(updateDetails.newVal.replace(/\D/g, ""));
        let currentStats = state.currentBuild.stats;
        let baseStats = state.currentBuild.baseClass.stats;

        // if we're within the acceptable range, do things
        if (cleanedNew >= baseStats[updateDetails.statName] && cleanedNew < 100) {
          let difference = cleanedNew - updateDetails.oldVal;
          currentStats[updateDetails.statName] = cleanedNew;
          currentStats.level += difference;
        } else {

          // otherwise just reset the value
          currentStats[updateDetails.statName] = updateDetails.oldVal;
        }
      }
    },
    updateOptimalStat(state, updateDetails) {
      if (typeof updateDetails.newVal === "string" ) {
        let cleanedNew = Number(updateDetails.newVal.replace(/\D/g, ""));
        state.currentOptimalBuild.stats[updateDetails.statName] = cleanedNew;
      }
    },

    incrementStat(state, statName) {
      if (state.currentBuild.stats[statName] < 99) {
        state.currentBuild.stats[statName]++;
        state.currentBuild.stats.level++;
      }
    },
    decrementStat(state, statName) {
      if (
        state.currentBuild.stats[statName] >
        state.currentBuild.baseClass.stats[statName]
      ) {
        state.currentBuild.stats[statName]--;
        state.currentBuild.stats.level--;
      }
    },

    incrementSlot(state) {
      if (state.currentBuild.slots < 12) {
        state.currentBuild.slots++;
      }
    },
    decrementSlot(state) {
      if (state.currentBuild.slots > 0 ) {
        state.currentBuild.slots--;
      }
    },

    incrementOptimalStat(state, statName) {
      if (state.currentOptimalBuild.stats[statName] < 99) {
        state.currentOptimalBuild.stats[statName]++;
        state.currentOptimalBuild.stats.level++;
      }
    },
    decrementOptimalStat(state, statName) {
      if (
        state.currentOptimalBuild.stats[statName] > 0
      ) {
        state.currentOptimalBuild.stats[statName]--;
        state.currentOptimalBuild.stats.level--;
      }
    },
    toggleLocations(state){
      state.showLocations = !state.showLocations;
    },
    updateEquipment(state, updateDetails){
      state.currentBuild.equipment[updateDetails.type] = updateDetails.selected;
    },
    updateWeapon(state, updateDetails){
      state.currentBuild.weapons[updateDetails.type] = updateDetails.selected;
    },
    updateWeaponInfusion(state, updateDetails){
      console.log(state.currentBuild)
      state.currentBuild.infusions[updateDetails.type] = updateDetails.selected;
    },
    updateWeaponSkill(state, updateDetails){
      state.currentBuild.skills[updateDetails.type] = updateDetails.selected;
    }
  },
  actions: {

  },
  modules: {

  }
})
