<template>
  <v-row v-show="expand" class="mt-1">
    <v-col :cols="12">
      <v-card id="locations" class="max-width">
        <v-card-title class="pb-0">Locations</v-card-title>
        <v-list three-line class="pt-0">
          <template v-for="(item, index) in locations">
            <v-subheader
              v-if="item.header"
              :key="item.header"
              v-text="item.header"
            ></v-subheader>
    
            <v-divider
              v-else-if="item.divider"
              :key="index"
              :inset="item.inset"
            ></v-divider>
    
            <v-list-item
              v-else
              :key="item.title"
            >
              <v-list-item-avatar>
                <v-img :src="item.image"></v-img>
              </v-list-item-avatar>
    
              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
                <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'EquipmentLocations',
    props: {
      expand: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      locations: [
        { header: "Weapon Locations" },
        {
          image: "https://fakeimg.pl/128x128/121212/1e1e1e/",
          title: "1",
          subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
          location,
        },
        { divider: true, inset: true },
        {
          image: "https://fakeimg.pl/128x128/121212/1e1e1e/",
          title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
          subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
        },
        { divider: true, inset: true },
        {
          image: "https://fakeimg.pl/128x128/121212/1e1e1e/",
          title: "Oui oui",
          subtitle:
            '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
        },
        { divider: true, inset: true },
        {
          image: "https://fakeimg.pl/128x128/121212/1e1e1e/",
          title: "Birthday gift",
          subtitle:
            '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
        },
      ],
    }),
  }
</script>
