<template>
  <v-autocomplete :label="label" :items="armors" item-text="name" item-value="id" return-object cache-items disabled outlined dense @change="updateArmor"></v-autocomplete>
</template>

<script>
  export default {
    name: 'ArmorSlot',
    props: {
      armorType: {
        type: String,
        required: true
      },
      armors: {
        type: Array,
        required: true
      },
      label: {
        type: String,
        required: true
      }
    },
    methods: {
      updateArmor: function(newArmor){
        this.$store.commit('updateEquipment', {type: this.armorType, selected: newArmor});
      }
    },
    data: () => ({

    }),
  }
</script>
